exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-clients-index-tsx": () => import("./../../../src/pages/clients/index.tsx" /* webpackChunkName: "component---src-pages-clients-index-tsx" */),
  "component---src-pages-contact-me-index-tsx": () => import("./../../../src/pages/contact-me/index.tsx" /* webpackChunkName: "component---src-pages-contact-me-index-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-how-can-i-help-index-tsx": () => import("./../../../src/pages/how-can-i-help/index.tsx" /* webpackChunkName: "component---src-pages-how-can-i-help-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-my-process-index-tsx": () => import("./../../../src/pages/my-process/index.tsx" /* webpackChunkName: "component---src-pages-my-process-index-tsx" */),
  "component---src-pages-my-story-index-tsx": () => import("./../../../src/pages/my-story/index.tsx" /* webpackChunkName: "component---src-pages-my-story-index-tsx" */),
  "component---src-pages-outcomes-index-tsx": () => import("./../../../src/pages/outcomes/index.tsx" /* webpackChunkName: "component---src-pages-outcomes-index-tsx" */),
  "component---src-pages-training-index-tsx": () => import("./../../../src/pages/training/index.tsx" /* webpackChunkName: "component---src-pages-training-index-tsx" */),
  "component---src-pages-training-non-pharma-industry-index-tsx": () => import("./../../../src/pages/training/non-pharma-industry/index.tsx" /* webpackChunkName: "component---src-pages-training-non-pharma-industry-index-tsx" */),
  "component---src-pages-training-pharma-industry-index-tsx": () => import("./../../../src/pages/training/pharma-industry/index.tsx" /* webpackChunkName: "component---src-pages-training-pharma-industry-index-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */)
}

